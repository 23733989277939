import React from 'react';
import styled from 'styled-components';
import { SocialMedia, ScrollToTopButton, RenderWhenReady } from 'components';
import { Link } from 'gatsby';
import { AnimatePresence } from 'framer-motion';
import { isMobileOnly } from 'react-device-detect';
import { useLocation } from '@reach/router';
import AgencyXLogo from 'images/shared/AgencyXLogo';
import { GlobalComNetwork, EmailIcon, MobileIcon, LocationIcon } from 'images/footer';

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <Container pathname={pathname}>
      <Row>
        <FooterSection>
          <GatsbyLink
            pathname={pathname}
            to="/"
            onClick={(e) => {
              if (pathname !== '/logobook/') {
                e.preventDefault();
              }
            }}>
            <StyledAgencyXLogo />
          </GatsbyLink>
          <h3>
            Campaigns.
            <br />
            Evolved.
          </h3>
          <RenderWhenReady>
            {!isMobileOnly && (
              <FindUs>
                <h4>Find Us On</h4>
                <SocialMedia />
              </FindUs>
            )}
          </RenderWhenReady>
        </FooterSection>
        <FooterSection>
          <ContactDetails>
            <h4>Contact</h4>
            <div>
              <EmailIcon />
              <p>
                <Link
                  style={{ color: '#fff', cursor: 'pointer', textDecoration: 'underline' }}
                  to="/contact/">
                  Email Us
                </Link>
              </p>
              <MobileIcon />
              <p>
                <a href="tel:+35316706122" style={{ color: '#fff' }}>
                  01 6706122
                </a>
              </p>
              <LocationIcon />
              <p>
                12 Hatch Street Lower, Dublin,
                <br />
                D02 R682, Ireland
              </p>
            </div>
          </ContactDetails>
          <a href="https://www.gcpr.net/regions/dublin/" target="_blank" rel="noreferrer">
            <StyledGlobalComLogo />
          </a>
        </FooterSection>
      </Row>
      <Row>
        <FooterSection>
          <RenderWhenReady>
            {isMobileOnly && (
              <FindUs>
                <h4>Find Us On</h4>
                <SocialMedia />
              </FindUs>
            )}
          </RenderWhenReady>
          <SmallPrint>
            © {new Date().getFullYear()} Agency X. All rights reserved.
            <br />
            AgencyX Limited, registered in Ireland, Reg. No. 472825, 12 Hatch Street Lower, Dublin,
            D02 R682, Ireland
          </SmallPrint>
          <SmallPrintLinks>
            <Link to="/terms-and-conditions/">Terms And Conditions</Link>
            <Link to="/privacy-policy/">Privacy Policy</Link>
            <a href="/assets/ai-usage-policy.pdf">AI Policy</a>
            <Link to="/cookie-policy/">Cookie Policy</Link>
            <span role="button" tabIndex={0} id="ot-sdk-btn" className="ot-sdk-show-settings">
              Cookie Preferences
            </span>
          </SmallPrintLinks>
        </FooterSection>
      </Row>
      <AnimatePresence>
        {!isMobileOnly && pathname !== '/logobook/' && <ScrollToTopButton />}
      </AnimatePresence>
    </Container>
  );
};

const GatsbyLink = styled(Link)`
  cursor: ${({ pathname }) => (pathname === '/logobook/' ? 'pointer' : 'default')};
  display: inline;
  width: 11rem;
`;

const StyledAgencyXLogo = styled(AgencyXLogo)`
  position: relative;
  z-index: 2;
  padding: 0.2em 0;
  width: 10rem;
  margin: 3rem 0 2rem;

  path {
    fill: white;
    &:nth-child(2) {
      fill: var(--ax-gold);
    }
  }
`;

const StyledGlobalComLogo = styled(GlobalComNetwork)`
  position: relative;
  width: 12rem;
  margin: 2rem 0;
`;

const Container = styled.footer`
  background-color: var(--ax-black);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  grid-column: 1 / -1;
  max-width: 100vw;
  padding-bottom: 1.25rem;
  position: relative;
  top: ${({ pathname }) => pathname === '/logobook/' && '-30px'};
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1600px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.25rem;
  @media screen and (min-width: 48rem) {
    padding: 0 6.25%;
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 48rem) {
    padding: 0 9%;
  }
`;

const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    color: #fff;
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 0.01em;
    line-height: 1.208em;
    margin-bottom: 0.4em;
    text-transform: capitalize;
  }
  h4 {
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: 0.0125em;
    text-transform: uppercase;
    margin: 1.9em 0 1.65em;
    @media screen and (min-width: 48rem) {
      margin-top: 0;
    }
  }

  @media screen and (min-width: 48rem) {
    &:nth-of-type(2) {
      justify-self: end;
      margin-right: 6rem;
      gap: 1rem;
    }
  }
`;

const ContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    margin: 1.5em 0;
  }
  div {
    align-items: center;
    display: grid;
    grid-row-gap: 1rem;
    padding: 0;
    grid-template-columns: 2.95rem 1fr;
    grid-template-rows: repeat(2, 1.625rem);
  }
  svg {
    width: 1.5rem;
  }
  p {
    color: #fff;
    font-size: 0.875rem;
    line-height: 1em;
    &:last-of-type {
      position: relative;
      top: 0.55em;
    }
  }
  @media screen and (min-width: 48rem) {
    h4 {
      margin: 2.9em 0 1.5em;
    }
  }
`;

const SmallPrint = styled.p`
  color: #fff;
  font-size: 0.75rem !important;
  margin: 0.75rem 0;
`;

const SmallPrintLinks = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.75rem !important;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 1rem;
  width: 100%;

  a {
    color: #fff;
  }

  #ot-sdk-btn,
  button {
    background-color: transparent !important;
    border: none !important;
    color: #fff !important;
    cursor: pointer !important;
    font-size: 12px !important;
    line-height: unset !important;
    padding: 0 !important;
    text-decoration: underline !important;

    &:link,
    &:visited,
    &:hover,
    &:active,
    &:focus {
      background-color: transparent !important;
    }
  }
  @media screen and (min-width: 48rem) {
    max-width: 42em;
  }
`;

const FindUs = styled.div`
  display: grid !important;
  grid-template-columns: 1fr !important;
  padding-top: 1rem !important;
  width: 100%;
  div {
    grid-column: span 1;
  }
`;

export default Footer;
